.reference {
    min-height: calc(100vh - 210px);
}

.reference h1 {
    margin: 50px auto;
}

.text_section {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.gallery {
    max-width: 80%;
    margin: 100px auto 0 auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(330px, .7fr));
    grid-gap: 10px;
}

