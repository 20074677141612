.about {
    min-height: calc(100vh - 200px);
}

.about > h1 {
    margin: 50px auto;
}

.img_container {
    width: clamp(250px, 80%, 1000px);
    margin: auto;
}

.about > p {
    text-align: justify;
    width: clamp(250px, 80%, 1000px);
    margin: auto;
    padding: 50px 0;
    font-size: 18px;
}