.App {
	text-align: center;
}

.round {
	position: absolute;
	top: 300px;
	left: 20px;
	width: 300px;
	height: 300px;
	border-radius: 50%;
	border: 30px solid #d7d7d7;
	opacity: 0.2;
	z-index: -2;
}

.round:nth-child(2) {
	top: 700px;
	right: 30px;
	left: auto;
	width: 550px;
	height: 550px;
}

.round:nth-child(3) {
	top: 1500px;
}

.totop {
	position: fixed;
	bottom: 90px;
	right: 5%;
	width: 30px;
	height: 50px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: red;
	cursor: pointer;
	box-shadow: 0 0 10px white;
	border-radius: 3px;
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	transition: 0.2s;
}

.active_totop {
	visibility: visible;
	opacity: 1;
	bottom: 60px;
}
