.container {
	min-height: 100vh;
}

.slide_container {
	width: calc(100vw - 15px);
	overflow: hidden;
}

.swiper {
	position: relative;
}

.slider_title {
	display: flex;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 0;
	padding-left: 50px;
	transform: translateY(-50%);
	width: 50%;
	height: 80px;
	background-image: linear-gradient(to right, #e4232a, transparent);
	color: white;
	text-shadow: 0 0 5px #333;
}

.home_slide {
	width: 100vw;
	height: calc(100vw / 2.5);
}

.swiper_two {
	margin-bottom: 50px;
	box-shadow: 10px black;
}

.service {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	height: 200px;
	box-shadow: 0 0 5px black;
	transition: 0.2s;
	cursor: pointer;
}

.service_img {
	width: 80px;
	margin: auto;
}

.service:hover {
	background-color: #e4232a;
	color: white;
}

.service:hover svg {
	fill: white;
}

.reference_section {
	width: 100%;
}

.reference_section > h1 {
	margin: 50px auto;
}

.reference_section button {
	margin: 50px 0;
	padding: 10px 20px;
	background-color: #fff;
	border: 2px solid #e4232a;
	transition: 0.2s;
	text-transform: uppercase;
	cursor: pointer;
}

.reference_section button:hover {
	color: white;
	background-color: #e4232a;
}

.reference_imgs {
	width: 100%;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(4, 1fr);
	margin-bottom: 50px;
}

.imgs_container {
	position: relative;
}

.imgs_container:hover::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #e4232a;
	opacity: 0.7;
	z-index: 1;
}

.imgs_container p {
	width: 90%;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	background-color: #fff;
	text-align: center;
	font-size: clamp(10px, 1.2vw, 16px);
	padding: 10px 0;
	box-shadow: 0 0 5px;
	text-transform: uppercase;
	z-index: 2;
	transition: 0.2s;
}

.imgs_container:hover p {
	color: white;
	background-color: #e4232a;
}

@media (max-width: 73.9375em) {
	.slide_container {
		width: 100vw;
	}

	.slider_title {
		font-size: 14px;
	}
}
@media (max-width: 63.9375em) {
	.reference_imgs {
		grid-template-columns: repeat(2, 1fr);
	}

	.imgs_container {
		margin-bottom: 40px;
	}

	.reference_imgs {
		margin-bottom: 0;
	}
}
