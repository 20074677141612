.certificates {
	min-height: calc(100vh - 210px);
	width: 100%;
}

.certificates h1 {
	margin: 50px auto;
}

.certificates_container {
	width: 60%;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-bottom: 50px;
}

.certificate {
	box-shadow: 0 0 18px rgba(215, 215, 215, 0.5);
	transition: 0.3s;
	cursor: pointer;
}
.certificate:hover {
	transform: scale(1.1);
}

@media (max-width: 63.9375em) {
	.certificates_container {
		width: 90%;
		grid-template-columns: repeat(2, 1fr);
	}
}
