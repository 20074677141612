.services {
	min-height: calc(100vh - 210px);
}

.services > h1 {
	margin: 50px auto;
}

.categories {
	max-width: 1500px;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 20px;
	justify-content: center;
	margin: 80px auto;
}

.category {
	width: 260px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	transition: 0.3s;
	padding: 0 15px 15px;
}

.category:hover {
	background-color: #e4232a;
	transform: scale(1.1);
	color: white;
}

.category:hover svg {
	fill: white;
}

.category h4 {
	text-align: center;
	padding: 10px 0;
}

.svg_container {
	display: flex;
	justify-content: center;
	padding: 20px;
}

.svg_container svg {
	height: 100px;
}

/************SINGLE serviceS*************/

.service_not_loaded {
	display: none;
}

.single_service {
	min-height: calc(100vh - 210px);
}

.single_service h1 {
	padding: 30px 0;
	text-align: center;
}

.service_container {
	width: 80%;
	margin: auto;
	display: grid;
	justify-content: center;
}

.image_container {
	align-self: center;
	margin: 10px;
	height: 300px;
	padding: 10px;
	display: flex;
	align-items: center;
	box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.3);
}

.img_side {
	width: 40%;
}

.image_container > p {
	width: 60%;
}

.service_container h3 {
	text-align: center;
	padding: 20px 10px;
}

.service_img {
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: center;
	padding: 10px;
}

.service_img img {
	height: 100%;
}

@media (max-width: 39.9375em) {
	.image_container {
		flex-direction: column;
		height: auto;
	}
	.image_container > p {
		width: 80%;
	}
}
