* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html,
body {
	min-height: 100vh;
	overflow-x: hidden;
	font-family: Montserrat;
	font-size: 15px;
}

a {
	color: inherit;
	text-decoration: none;
}

ul {
	list-style: none;
}

main {
	position: relative;
	overflow: hidden;
}

.swiper-button-prev:after, .swiper-button-next:after {
	text-shadow: 0 0 10px #333;
	color: white;
}