.upper {
	background-color: #e4232a;
	color: white;
}

.upper_container,
.lower_container {
	height: 55px;
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;
}
.contact,
.phone,
.mail,
.soc_lang {
	display: flex;
	align-items: center;
}

.phone {
	margin-right: 20px;
}

.contact svg {
	margin-right: 5px;
}

.soc {
	margin-right: 20px;
	display: flex;
	align-items: center;
}

.singleSvg {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
	transition: .2s;
}

.singleSvg:hover {
	background-color: rgba(225, 225, 225, .3);;
}

.lower {
	position: relative;
}

.lower_container {
	height: 85px;
}

.lower_container > svg {
	font-size: 40px;
}

.logo {
	flex: 0.2;
	user-select: none;
	display: flex;
    justify-content: flex-start;
}

.nav {
	flex: 0.8;
	display: flex;
	align-items: center;
}

.opened {
	position: absolute;
	width: 70%;
	max-width: 400px;
	top: 100%;
	right: 0;
	z-index: 5;
	flex-direction: column;
	background-color: rgba(228, 35, 42, 0.95);
	animation: menuOpen .3s ease-out forwards;
}

@keyframes menuOpen {0% {right: -100%} 100% {right: 0}}

.opened ul {
	flex-direction: column;
}

.opened ul li {
	padding: 100px 2%;
	user-select: none;
}

.opened ul li a {
	padding: 30px 2% !important;
	color: white;
}

.nav ul {
	width: 100%;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.nav ul li {
	cursor: pointer;
	display: contents;
	padding: 10px 2%;
	margin: 0 3px;
	font-weight: 500;
}

.nav ul li:nth-child(6) {
	margin-right: 0;
	padding-right: 0;
}

.nav ul li:hover {
	color: #e4232a;
}

.nav ul li a {
	padding: 10px 2%;
	white-space: nowrap;
}

.lang {
	height: 65px;
	width: 20px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.current {
	cursor: pointer;
	width: 60px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px 5.6px 10px;
	font-size: 20px;
    margin-bottom: 5.2px;
	border-radius: 50%;
	transform: translateY(3px);
} 

.current:hover {
	background-color: rgba(255, 255, 255, .3);
} 

.sub_langs {
	position: absolute;
	bottom: -80%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 6;
	animation: langs .2s ease-out forwards ;
}

@keyframes langs {0% {bottom: -100%} 100% {bottom: -80%}}

.sub_langs button {
	width: 70px;
	height: 30px;
	font-weight: 600;
	background-color: #eee;
	border: 1px solid #e4232a;
	cursor: pointer;
}

.sub_langs button:nth-child(1) {
	margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
	.contact {
		flex-direction: column;
		align-items: flex-start;
		font-size: 12px;
		line-height: 24px;
	}
}

@media screen and (max-width: 350px) {
	.phone p {
		font-size: 10px;
	}
}
