.partners {
	min-height: calc(100vh - 210px);
}

.partners > h1 {
	margin: 50px auto;
}

.container {
	width: fit-content;
	margin: auto;
	padding-bottom: 50px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 50px;
}

.partner {
	width: 13vw;
	height: 13vw;
	max-height: 180px;
	max-width: 180px;
	margin: auto;
	padding: 10px;
	display: flex;
	align-items: center;
	border-radius: 20px;
	box-shadow: 7px 8px 20px grey;
	transition: 0.3s;
}

.partner:hover {
	transform: scale(1.1);
}

@media (max-width: 39.9375em) {
	.container {
		width: 90%;
		margin: auto;
		display: grid;
		grid-template-columns: 1fr;
	}

	.partner {
		height: 230px;
		width: 230px;
		margin: 10px auto;
	}
}
