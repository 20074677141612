.footer {
	width: 100%;
	height: 70px;
	background-color: #e4232a;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5%;
	color: white;
}

.rights {
	display: flex;
	align-items: center;
}

.rights p {
	padding-right: 10px;
}

.rights div {
	padding-left: 10px;
	position: relative;
}

.rights div::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 1px;
	height: 25px;
	background-color: #fff;
}

.rights div svg {
	fill: white;
	width: 60px;
}

@media screen and (max-width: 600px) {
	.rights p {
		font-size: 10px;
	}
}
